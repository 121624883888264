<template>
    <div class="directorList">
        <!-- <div class="padd20 borbut20 paddboutno0">
            <div class="topSearchSou">
                <div class="searchdan time">
                    <div class="title">创建时间：</div>
                    <el-date-picker v-model="pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div class="searchdan">
                    <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                    <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' >重置数据</el-button>
                </div>
            </div>
        </div> -->
        <div class="TableList padd20">
            <div class="duoshao">
                <div class="title">获取导演列表</div>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table :data="TableList" empty-text="暂无数据">
                <el-table-column prop="id" label="id"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="description" label="描述"></el-table-column>
                
                <el-table-column prop="icon" label="图标"></el-table-column>
                
                <!-- <el-table-column prop="date" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDialog(scope.row)" style="margin-left:0">操作</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            <!-- <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @size-change="handleSizeChange" :page-sizes="[20, 40, 50, 100]" @current-change="DialogCurrentChange" background layout="sizes,prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            paramData:{},//表格参数
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页on


            pickerTime:'',//时间
            TableList:[],//数据列表
        };
    },
    
    mounted() {
        this.onAllDataList()
    },

    methods: {
        //操作
        onDialog(){
            
        },
        //商品搜索
        onSearch(){
            this.paramData = {
                start_created_at: this.param.pickerTime ? this.param.pickerTime[0] : "", //开始日期
                end_created_at: this.param.pickerTime ? this.param.pickerTime[1] : "", //结束日期
            }
            this.paramPage = 1
            this.onAllDataList()
        },
         // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.onAllDataList()
        },
        //数据列表
        onAllDataList(){
            this.TableList = []
            var param = this.paramData
            // param.per_page = this.per_page
            // param.page = this.paramPage
            this.$service.get(this.$api.getMenu,param, (res)=> {
                if(res.code == '200'){
                    this.TableList = res.data
                    // this.lvtotal = res.data.total
                    // this.per_page = res.data.per_page
                }
            })
        },
         //每页多少条触发条件
        handleSizeChange(val){
            this.per_page = val
            this.onAllDataList()
        },
    },
    
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>